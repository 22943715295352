import Routes from "./routes/routes";
import GlobalStyle from "./components/common/global-style/global-style";

const App = () => {
  return (
    <>
      <Routes />
      <GlobalStyle />
    </>
  );
};

export default App;
