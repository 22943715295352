import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  :root {
    --green: #74B62E;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
   font-family: 'Montserrat', sans-serif;
  }
  ul, li {
    list-style: none;
  }
  a {
    text-decoration: none;
    color: #000;
  }
  h2 {
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 15px;
  }
  h3 {
    font-weight: 600;
    font-size: 28px;
  }
  h4 {
    font-weight: 400;
    font-size: 20px;
    color: #141414;
  }
  input {
    border: none;
    outline: none;
    ::placeholder {
      color: #A3A3A3;
      font-weight: 300;
    }
  }
  @media screen {
    h2,h3 {
      font-size: 25px;
      margin-bottom: 4px;
    }
  }
  @media (min-width: 1800px) {
    h3 {
      margin-bottom: 30px;
      font-size: 28px;
    }
    h2 {
      margin-bottom: 30px;
    }
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  .small {
    font-size: 14px;
  }
`;

export const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  @media (mix-width: 2000px) {
    width: 1500px;
  }
  @media (max-width: 800px) {
    width: 100%;
    padding: 0 20px;
  }
  @media (min-width: 1800px) {
    width: 1100px !important;
  }
`;
export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const Grid4 = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 36px;
  @media (min-width: 1350px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    &.forMain {
      grid-template-columns: repeat(16, 16fr);
      overflow-x: auto;
      flex-wrap: wrap;
      .product-card {
        width: 250px;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  @media (min-width: 1800px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
export const Grid2 = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 80px;
  align-items: center;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  @media (min-width: 1800px) {
    grid-gap: 30px;
  }
`;
export const Grid3 = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  align-items: center;
  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export default GlobalStyle;
