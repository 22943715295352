import { ReactNode, LazyExoticComponent, ComponentType, lazy } from "react";

export interface IRoute {
  // Path, like in basic prop
  path: string;
  // Exact, like in basic prop
  exact: boolean;
  // Preloader for lazy loading
  fallback: NonNullable<ReactNode> | null;
  // Lazy Loaded component
  component?: LazyExoticComponent<ComponentType<any>>;
  // Sub routes
  routes?: IRoute[];
  // Redirect path
  redirect?: string;
  // If router is private, this is going to be true
  private?: boolean;
}
const loader = (
  <div className="divLoader">
    <svg className="svgLoader" viewBox="0 0 100 100" width="4em" height="4em">
      <path
        ng-attr-d="{{config.pathCmd}}"
        ng-attr-fill="{{config.color}}"
        stroke="none"
        d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50"
        fill="#fff"
        transform="rotate(179.719 50 51)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 50 51;360 50 51"
          keyTimes="0;1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        ></animateTransform>
      </path>
    </svg>
  </div>
);

export const routes: IRoute[] = [
  {
    path: "/home",
    component: lazy(() => import("../pages/home/home")),
    exact: true,
    private: false,
    fallback: loader,
  },
  {
    path: "/promotions",
    component: lazy(() => import("../pages/products/products")),
    exact: true,
    private: false,
    fallback: loader,
  },
  {
    path: "/news",
    component: lazy(() => import("../pages/news/news")),
    exact: true,
    private: false,
    fallback: loader,
  },
  {
    path: "/shops",
    component: lazy(() => import("../pages/markets/markets")),
    exact: true,
    private: false,
    fallback: loader,
  },
  {
    path: "/about",
    component: lazy(() => import("../pages/about/about")),
    exact: true,
    private: false,
    fallback: loader,
  },
  {
    path: "",
    exact: false,
    component: lazy(() => import("../pages/home/home")),
    fallback: loader,
  },
];
